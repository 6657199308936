<template>
  <v-container class="pa-0">
    <v-layout>
      <v-flex class="imagen_fondo ">
            <v-col cols="12" class="pa-0">
              <v-img
              alt="Banner"
              class=""
              src="https://cdn.geducar.com/4850715b371e7a2f495c37234753a5ec/1592863290_auditorium-benches-chairs-class-207691.jpg"
              transition="scale-transition"
              width="100%"
              />  
            </v-col>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm4 md4 pa-5>
              <v-img
              alt="Logo"
              class="ma-auto mt-5 mb-5"
              src="https://cdn.geducar.com/4850715b371e7a2f495c37234753a5ec/1592864359_Logo-Geducar-ContornoB.png"
              transition="scale-transition"
              width="80%"
              align="center"
              justify="center"
              />  
            <h1>Lorem ipsum </h1>
            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus soluta reiciendis veniam, assumenda sunt voluptate ullam, aperiam quos officia, voluptatem eos natus perferendis beatae? Cupiditate maxime vel vitae possimus ut?</p>
            <p class="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus soluta reiciendis veniam, assumenda sunt voluptate ullam, aperiam quos officia, voluptatem eos natus perferendis beatae? Cupiditate maxime vel vitae possimus ut?</p>
      </v-flex>
      <v-flex xs12 sm8 md8 pa-5>
        <v-card class="pa-5" >
          <div class="d-inline-flex">
            <radial-progress-bar :diameter="75"
                       :completed-steps="completedSteps"
                       :total-steps="totalSteps">
                <p class="pt-3 caption" >{{ completedSteps }} de {{ totalSteps }}</p>
            </radial-progress-bar>
            <v-card-title> Datos del aspirante </v-card-title>
          </div>
            <v-divider></v-divider>
<form>
  <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Datos del aspirante</v-stepper-step>
            <v-divider></v-divider>
          <v-stepper-step step="2">Referencias</v-stepper-step>
        </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-layout wrap>
          <v-row 
          class="pa-2 col-md-12">
            <v-col
            cols="12"
            md="6"
            >
              <v-select class="pt-5"
                v-model="sede"
                :items="items"
                :error-messages="sedeErrors"
                label="Sede"
                required
                @change="$v.sede.$touch()"
                @blur="$v.sede.$touch()"
              ></v-select>
            </v-col>
            <v-col
            cols="12"
            md="6"
            >
              <div class="d-inline-flex col-md-12">
              <div class="col-md-6">
                <p class="caption">Fotografía</p>
              </div>
              <div class="col-md-6">
                <label class="custom-file-upload">
                <v-icon color="azul">add_circle</v-icon>
                <input type="file" accept="image/jpeg" @change=uploadImage>
                </label>
              </div>
              </div>
              </v-col>
          </v-row>
          <v-row
          class="pa-2">
              <v-col
              cols="12"
              md="6"
              >
              <v-text-field
                v-model="name"
                :error-messages="nameErrors"
                :counter="10"
                label="Nombres"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              ></v-text-field>
              </v-col>
              <v-col
              cols="12"
              md="6"
              >
              <v-text-field
                v-model="apellido"
                :error-messages="apellidoErrors"
                :counter="10"
                label="Apellidos"
                required
                @input="$v.apellido.$touch()"
                @blur="$v.apellido.$touch()"
              ></v-text-field>
              </v-col>
          </v-row>
          <v-row
          class="pa-2">
              <v-col
              cols="12"
              md="4"
              >
              <v-select
                  v-model="genero"
                  :items="generos"
                  :error-messages="generoErrors"
                  label="Género"
                  required
                  @change="$v.genero.$touch()"
                  @blur="$v.genero.$touch()"
              ></v-select>
              </v-col>
              <v-col
              cols="12"
              md="4"
              >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha de Nacimiento"
                    prepend-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" locale="es" @input="menu2  = false"></v-date-picker>
              </v-menu>
              </v-col>
              <v-col
              cols="12"
              md="4"
              >
              <v-text-field
                  @keypress="onlyNumbers"
                  v-model="dpi"
                  :error-messages="dpiErrors"
                  label="DPI/CUI"
                  required
                  :counter="15"
                  @input="$v.dpi.$touch()"
                  @blur="$v.dpi.$touch()"
              ></v-text-field>
              </v-col>
          </v-row>
          <v-row
          class="pa-2">
              <v-col
              cols="12"
              md="3"
              >
              <v-select
                  v-model="ciclo"
                  :items="ciclos"
                  :error-messages="cicloErrors"
                  label="Ciclo"
                  required
                  @change="$v.ciclo.$touch()"
                  @blur="$v.ciclo.$touch()"
              ></v-select>
              </v-col>
              <v-col
              cols="12"
              md="3"
              >
              <v-select
                  v-model="jornada"
                  :items="jornadas"
                  :error-messages="jornadaErrors"
                  label="Jornada"
                  required
                  @change="$v.jornada.$touch()"
                  @blur="$v.jornada.$touch()"
              ></v-select>
              </v-col>
              <v-col
              cols="12"
              md="3"
              >
              <v-select
                  v-model="nivel"
                  :items="niveles"
                  :error-messages="nivelErrors"
                  label="Nivel"
                  required
                  @change="$v.nivel.$touch()"
                  @blur="$v.nivel.$touch()"
              ></v-select>
              </v-col>
              <v-col
              cols="12"
              md="3"
              >
              <v-select
                  v-model="grado"
                  :items="grados"
                  :error-messages="gradoErrors"
                  label="Grado"
                  required
                  @change="$v.grado.$touch()"
                  @blur="$v.grado.$touch()"
              ></v-select>
              </v-col>
          </v-row>
        </v-layout> 
          <v-divider></v-divider>
          <v-tabs
          v-model="tab"
          flat
          icons-and-text>
            <v-tab href="#tab1">Padre</v-tab>
            <v-tab href="#tab2">Madre</v-tab>
            <v-tab href="#tab3">Encargado</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" value="tab1">
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      v-model="namepadre"
                      :error-messages="namepadreErrors"
                      :counter="10"
                      label="Nombres Padre"
                      required
                      @input="$v.namepadre.$touch()"
                      @blur="$v.namepadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      v-model="apellidopadre"
                      :error-messages="apellidopadreErrors"
                      :counter="10"
                      label="Apellidos Padre"
                      required
                      @input="$v.apellidopadre.$touch()"
                      @blur="$v.apellidopadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                    @keypress="onlyNumbers"
                    v-model="dpipadre"
                    :error-messages="dpipadreErrors"
                    label="DPI/CUI Padre"
                    required
                    :counter="15"
                    @input="$v.dpipadre.$touch()"
                    @blur="$v.dpipadre.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="8"
                >
                  <v-text-field
                      v-model="direccionpadre"
                      :error-messages="direccionpadreErrors"
                      :counter="10"
                      label="Dirección de domicilio Padre"
                      required
                      @input="$v.direccionpadre.$touch()"
                      @blur="$v.direccionpadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="telefonodomiciliopadre"
                      :error-messages="telefonodomiciliopadreErrors"
                      :counter="11"
                      label="Teléfono domiciliar"
                      required
                      @input="$v.telefonodomiciliopadre.$touch()"
                      @blur="$v.telefonodomiciliopadre.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="movilpadre"
                      :error-messages="movilpadreErrors"
                      :counter="10"
                      label="Móvil Padre"
                      required
                      @input="$v.movilpadre.$touch()"
                      @blur="$v.movilpadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      v-model="correopadre"
                      :error-messages="correopadreErrors"
                      :counter="10"
                      label="Correo Padre"
                      required
                      @input="$v.correopadre.$touch()"
                      @blur="$v.correopadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      v-model="profesionpadre"
                      :error-messages="profesionpadreErrors"
                      :counter="10"
                      label="Profesión/Ocupación Padre"
                      required
                      @input="$v.profesionpadre.$touch()"
                      @blur="$v.profesionpadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="telefonotrabajopadre"
                      :error-messages="telefonotrabajopadreErrors"
                      :counter="10"
                      label="Teléfono de trabajo Padre"
                      required
                      @input="$v.telefonotrabajopadre.$touch()"
                      @blur="$v.telefonotrabajopadre.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :key="1" value="tab2">
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      v-model="namemadre"
                      :error-messages="namemadreErrors"
                      :counter="10"
                      label="Nombres Madre"
                      required
                      @input="$v.namemadre.$touch()"
                      @blur="$v.namemadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      v-model="apellidomadre"
                      :error-messages="apellidomadreErrors"
                      :counter="10"
                      label="Apellidos Madre"
                      required
                      @input="$v.apellidomadre.$touch()"
                      @blur="$v.apellidomadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                    @keypress="onlyNumbers"
                    v-model="dpimadre"
                    :error-messages="dpimadreErrors"
                    label="DPI/CUI Madre"
                    required
                    :counter="15"
                    @input="$v.dpimadre.$touch()"
                    @blur="$v.dpimadre.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="8"
                >
                  <v-text-field
                      v-model="direccionmadre"
                      :error-messages="direccionmadreErrors"
                      :counter="10"
                      label="Dirección de domicilio Madre"
                      required
                      @input="$v.direccionmadre.$touch()"
                      @blur="$v.direccionmadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="telefonodomiciliomadre"
                      :error-messages="telefonodomiciliomadreErrors"
                      :counter="11"
                      label="Teléfono Domiciliar"
                      required
                      @input="$v.telefonodomiciliomadre.$touch()"
                      @blur="$v.telefonodomiciliomadre.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="movilmadre"
                      :error-messages="movilmadreErrors"
                      :counter="10"
                      label="Móvil Madre"
                      required
                      @input="$v.movilmadre.$touch()"
                      @blur="$v.movilmadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      v-model="correomadre"
                      :error-messages="correomadreErrors"
                      :counter="10"
                      label="Correo Madre"
                      required
                      @input="$v.correomadre.$touch()"
                      @blur="$v.correomadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="profesionmadre"
                      :error-messages="profesionmadreErrors"
                      :counter="10"
                      label="Profesión/Ocupación Madre"
                      required
                      @input="$v.profesionmadre.$touch()"
                      @blur="$v.profesionmadre.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      v-model="telefonotrabajomadre"
                      :error-messages="telefonotrabajomadreErrors"
                      :counter="10"
                      label="Teléfono de trabajo Madre"
                      required
                      @input="$v.telefonotrabajomadre.$touch()"
                      @blur="$v.telefonotrabajomadre.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item :key="1" value="tab3">
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="4"
                > 
                  <v-text-field
                      v-model="parentescoencargado"
                      :error-messages="parentescoencargadoErrors"
                      :counter="10"
                      label="Parentesco"
                      required
                      @input="$v.parentescoencargado.$touch()"
                      @blur="$v.parentescoencargado.$touch()"
                  ></v-text-field>
                </v-col> 
                <v-col
                cols="12"
                md="8"
                > 
                </v-col>
              </v-row>
                <v-row
                class="pa-2">
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      v-model="nombresencargado"
                      :error-messages="nombresencargadoErrors"
                      :counter="10"
                      label="Nombres Encargado"
                      required
                      @input="$v.nombresencargado.$touch()"
                      @blur="$v.nombresencargado.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      v-model="apellidoencargado"
                      :error-messages="apellidoencargadoErrors"
                      :counter="10"
                      label="Apellidos Encargado"
                      required
                      @input="$v.apellidoencargado.$touch()"
                      @blur="$v.apellidoencargado.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                    @keypress="onlyNumbers"
                    v-model="dpiencargado"
                    :error-messages="dpiencargadoErrors"
                    label="DPI/CUI Encargado"
                    required
                    :counter="15"
                    @input="$v.dpiencargado.$touch()"
                    @blur="$v.dpiencargado.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="8"
                >
                  <v-text-field
                      v-model="direccionencargado"
                      :error-messages="direccionencargadoErrors"
                      :counter="10"
                      label="Dirección de domicilio"
                      required
                      @input="$v.direccionencargado.$touch()"
                      @blur="$v.direccionencargado.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="telefonodomicilioencargado"
                      :error-messages="telefonodomicilioencargadoErrors"
                      :counter="10"
                      label="Teléfono domicilio Encargado"
                      required
                      @input="$v.telefonodomicilioencargado.$touch()"
                      @blur="$v.telefonodomicilioencargado.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              class="pa-2">
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="movilencargado"
                      :error-messages="movilencargadoErrors"
                      :counter="10"
                      label="Móvil Encargado"
                      required
                      @input="$v.movilencargado.$touch()"
                      @blur="$v.movilencargado.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      v-model="correoencargado"
                      :error-messages="correoencargadoErrors"
                      :counter="10"
                      label="Correo Encargado"
                      required
                      @input="$v.correoencargado.$touch()"
                      @blur="$v.correoencargado.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      v-model="profesionencargado"
                      :error-messages="profesionencargadoErrors"
                      :counter="10"
                      label="Profesión/Ocupación"
                      required
                      @input="$v.profesionencargado.$touch()"
                      @blur="$v.profesionencargado.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="3"
                >
                  <v-text-field
                      @keypress="onlyNumbers"
                      v-model="telefonotrabajoencargado"
                      :error-messages="telefonotrabajoencargadoErrors"
                      :counter="10"
                      label="Teléfono de Trabajo Encargado"
                      required
                      @input="$v.telefonotrabajoencargado.$touch()"
                      @blur="$v.telefonotrabajoencargado.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
        </v-tabs-items>
                <v-checkbox
                  v-model="checkbox"
                  :error-messages="checkboxErrors"
                  label="Seleccionar como encargado"
                  required
                  @change="$v.checkbox.$touch()"
                  @blur="$v.checkbox.$touch()"
                ></v-checkbox>
          <v-layout>
            <v-flex>
              <div class="text-right">
                <v-btn
                  color="primary"
                  @click="e1 = 2"
                >
                  Continue
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-layout wrap>
          <v-row 
            v-for="(input,k) in inputs" :key="k"
            class="pa-2"
            >
              <v-col
              cols="12"
              md="6"
              >
              <v-text-field
                class="form-control" 
                v-model="input.nombresreferencias"
                :error-messages="nombreErrors"
                :counter="10"
                label="Nombres"
              ></v-text-field>
              </v-col>
              <v-col
              cols="12"
              md="6"
              >
              <v-text-field
                :error-messages="apellidoErrors"
                :counter="10"
                label="Apellidos"
                class="form-control" 
                v-model="input.apellidosreferencias"
              ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                  class="form-control" 
                  v-model="input.telefonoreferencias"
                  :error-messages="nombreErrors"
                  :counter="10"
                  label="Teléfono"
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                  :error-messages="apellidoErrors"
                  :counter="10"
                  label="Correo"
                  class="form-control" 
                  v-model="input.correoreferencias"
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                  :error-messages="apellidoErrors"
                  :counter="10"
                  label="Tipo de relación"
                  class="form-control" 
                  v-model="input.relacionreferencias"
                ></v-text-field>
                </v-col>
                  <v-btn 
                    class="ml-2"
                    color="secondary"
                    text-center
                    icon
                    flat
                    @click="remove(k)" 
                    v-show="k || ( !k && inputs.length > 1)"
                  ><v-icon>remove_circle</v-icon>
                  </v-btn>
                  <v-btn 
                    class="ml-2"
                    icon
                    flat
                    @click="add(k)" 
                    v-show="k == inputs.length-1"
                  ><v-icon color="azul">add_circle</v-icon></v-btn>
          </v-row>
            <v-divider></v-divider>
          <v-row align="center" justify="center"
          class="pa-2"
          >
            <v-col
              cols="12"
              md="4"
            >
            <h3 class="pt-3 pb-3">Adjuntar Archivos</h3>
            <p class="primary--text caption">Instrucciones</p>
            <p class="caption text-justify"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi suscipit reprehenderit esse fugit neque maiores, officiis quaerat reiciendis ipsam consequuntur praesentium voluptas provident illo non quibusdam consectetur doloremque! At, voluptates?</p>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
            <div>
              <v-file-input small-chips multiple label="archivos"></v-file-input>
            </div>
            </v-col>
          </v-row>
        </v-layout>
            <v-divider></v-divider>
        <v-layout>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <h3>¿Cómo se enteró?</h3>
              <v-select
                  v-model="medio"
                  :items="medios"
                  :error-messages="medioErrors"
                  label="Medio"
                  @change="$v.medio.$touch()"
                  @blur="$v.medio.$touch()"
              ></v-select>
            </v-col>
          </v-row>
        </v-layout>
      
          <div class="text-right">
            <v-btn
              class="mr-2"
              color="secondary"
              @click="e1 = 1"
            >
              Regresar
            </v-btn>
              <v-btn
                color="primary"
                dark
                @click.stop="dialog = true"
              >
              Continuar
              </v-btn>
              <v-dialog
                  v-model="dialog"
                  max-width="1000"
              >

                <v-stepper v-model="a1">
                    <v-stepper-header>
                      <v-stepper-step :complete="a1 > 1" step="1">Datos del aspirante</v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step :complete="a1 > 2" step="2">Referencias </v-stepper-step>

                      <v-divider></v-divider>

                      <v-stepper-step step="3">Proceso finalizado con éxito</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <div>
                          <v-img 
                          width="15%"
                          align="center"
                          justify="center"
                          class="imagen_dialog"
                          src="https://cdn.geducar.com/4850715b371e7a2f495c37234753a5ec/1593483305_5e79342b35ca7.jpg"></v-img>
                        </div>
                        <v-layout wrap>
                          <v-row 
                          class="pa-2 col-md-12">
                            <v-col
                            cols="12"
                            md="12"
                            >
                              <v-text-field class="pt-5"
                                v-model="sede"
                                label="Sede"
                                readonly
                                @change="$v.sede.$touch()"
                                @blur="$v.sede.$touch()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row
                          class="pa-2">
                              <v-col
                              cols="12"
                              md="6"
                              >
                              <v-text-field
                                v-model="name"
                                label="Nombre"
                                readonly
                                @input="$v.name.$touch()"
                                @blur="$v.name.$touch()"
                              ></v-text-field>
                              </v-col>
                              <v-col
                              cols="12"
                              md="6"
                              >
                              <v-text-field
                                v-model="apellido"
                                label="Apellido"
                                readonly
                                @input="$v.apellido.$touch()"
                                @blur="$v.apellido.$touch()"
                              ></v-text-field>
                              </v-col>
                          </v-row>
                          <v-row
                          class="pa-2">
                              <v-col
                              cols="12"
                              md="4"
                              >
                              <v-text-field
                                  v-model="genero"
                                  label="Genero"
                                  readonly
                                  @change="$v.genero.$touch()"
                                  @blur="$v.genero.$touch()"
                              ></v-text-field>
                              </v-col>
                              <v-col
                              cols="12"
                              md="4"
                              >
                              <v-menu
                                v-model="menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ }">
                                  <v-text-field
                                    v-model="date"
                                    readonly
                                    label="Fecha de Nacimiento"
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="date" locale="es" ></v-date-picker>
                              </v-menu>
                              </v-col>
                              <v-col
                              cols="12"
                              md="4"
                              >
                              <v-text-field
                                  @keypress="onlyNumbers"
                                  v-model="dpi"
                                  readonly
                                  label="DPI/CUI"
                                  @input="$v.dpi.$touch()"
                                  @blur="$v.dpi.$touch()"
                              ></v-text-field>
                              </v-col>
                          </v-row>
                          <v-row
                          class="pa-2">
                              <v-col
                              cols="12"
                              md="3"
                              >
                              <v-text-field
                                  v-model="ciclo"
                                  readonly
                                  label="Ciclo"
                                  @change="$v.ciclo.$touch()"
                                  @blur="$v.ciclo.$touch()"
                              ></v-text-field>
                              </v-col>
                              <v-col
                              cols="12"
                              md="3"
                              >
                              <v-text-field
                                  v-model="jornada"
                                  readonly
                                  label="Jornada"
                                  @change="$v.jornada.$touch()"
                                  @blur="$v.jornada.$touch()"
                              ></v-text-field>
                              </v-col>
                              <v-col
                              cols="12"
                              md="3"
                              >
                              <v-text-field
                                  v-model="nivel"
                                  readonly
                                  label="Nivel"
                                  @change="$v.nivel.$touch()"
                                  @blur="$v.nivel.$touch()"
                              ></v-text-field>
                              </v-col>
                              <v-col
                              cols="12"
                              md="3"
                              >
                              <v-text-field
                                  v-model="grado"
                                  readonly
                                  label="Grado"
                                  @change="$v.grado.$touch()"
                                  @blur="$v.grado.$touch()"
                              ></v-text-field>
                              </v-col>
                          </v-row>
                        </v-layout> 
                      <v-divider></v-divider>
                        <v-card-title> Datos de los padres y/o encargado </v-card-title>
                        <p> Padre </p>
                        <v-row
                        class="pa-2">
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                                v-model="namepadre"
                                label="Nombres Padre"
                                readonly
                                @input="$v.namepadre.$touch()"
                                @blur="$v.namepadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                                v-model="apellidopadre"
                                label="Apellidos Padre"
                                readonly
                                @input="$v.apellidopadre.$touch()"
                                @blur="$v.apellidopadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                              v-model="dpipadre"
                              label="DPI/CUI Padre"
                              readonly
                              @input="$v.dpipadre.$touch()"
                              @blur="$v.dpipadre.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                        class="pa-2">
                          <v-col
                          cols="12"
                          md="8"
                          >
                            <v-text-field
                                v-model="direccionpadre"
                                label="Dirección de domicilio Padre"
                                readonly
                                @input="$v.direccionpadre.$touch()"
                                @blur="$v.direccionpadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                                v-model="telefonodomiciliopadre"
                                label="Teléfono domiciliar"
                                readonly
                                @input="$v.telefonodomiciliopadre.$touch()"
                                @blur="$v.telefonodomiciliopadre.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                        class="pa-2">
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="movilpadre"
                                label="Móvil Padre"
                                readonly
                                @input="$v.movilpadre.$touch()"
                                @blur="$v.movilpadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="correopadre"
                                label="Correo Padre"
                                readonly
                                @input="$v.correopadre.$touch()"
                                @blur="$v.correopadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="profesionpadre"
                                label="Profesión/Ocupación Padre"
                                readonly
                                @input="$v.profesionpadre.$touch()"
                                @blur="$v.profesionpadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="telefonotrabajopadre"
                                label="Teléfono de trabajo Padre"
                                readonly
                                @input="$v.telefonotrabajopadre.$touch()"
                                @blur="$v.telefonotrabajopadre.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <p class="pt-5"> Madre </p>
                        <v-row
                        class="pa-2">
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                                v-model="namemadre"
                                label="Nombres Madre"
                                readonly
                                @input="$v.namemadre.$touch()"
                                @blur="$v.namemadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                                v-model="apellidomadre"
                                label="Apellidos Madre"
                                readonly
                                @input="$v.apellidomadre.$touch()"
                                @blur="$v.apellidomadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                              v-model="dpimadre"
                              label="DPI/CUI Madre"
                              readonly
                              @input="$v.dpimadre.$touch()"
                              @blur="$v.dpimadre.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                        class="pa-2">
                          <v-col
                          cols="12"
                          md="8"
                          >
                            <v-text-field
                                v-model="direccionmadre"
                                label="Dirección de domicilio Madre"
                                readonly
                                @input="$v.direccionmadre.$touch()"
                                @blur="$v.direccionmadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="4"
                          >
                            <v-text-field
                                v-model="telefonodomiciliomadre"
                                label="Teléfono Domiciliar"
                                readonly
                                @input="$v.telefonodomiciliomadre.$touch()"
                                @blur="$v.telefonodomiciliomadre.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                        class="pa-2">
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="movilmadre"
                                label="Móvil Madre"
                                readonly
                                @input="$v.movilmadre.$touch()"
                                @blur="$v.movilmadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="correomadre"
                                :error-messages="correomadreErrors"
                                :counter="10"
                                label="Correo Madre"
                                required
                                @input="$v.correomadre.$touch()"
                                @blur="$v.correomadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                @keypress="onlyNumbers"
                                v-model="profesionmadre"
                                :error-messages="profesionmadreErrors"
                                :counter="10"
                                label="Profesión/Ocupación Madre"
                                required
                                @input="$v.profesionmadre.$touch()"
                                @blur="$v.profesionmadre.$touch()"
                            ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="3"
                          >
                            <v-text-field
                                v-model="telefonotrabajomadre"
                                :error-messages="telefonotrabajomadreErrors"
                                :counter="10"
                                label="Teléfono de trabajo Madre"
                                required
                                @input="$v.telefonotrabajomadre.$touch()"
                                @blur="$v.telefonotrabajomadre.$touch()"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <p class="pt-5">Encargado</p>
                          <v-row
                          class="pa-2">
                            <v-col
                            cols="12"
                            md="4"
                            >
                              <v-text-field
                                  v-model="nombresencargado"
                                  label="Nombres Encargado"
                                  readonly
                                  @input="$v.nombresencargado.$touch()"
                                  @blur="$v.nombresencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                              <v-text-field
                                  v-model="apellidoencargado"
                                  label="Apellidos Encargado"
                                  readonly
                                  @input="$v.apellidoencargado.$touch()"
                                  @blur="$v.apellidoencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                              <v-text-field
                                v-model="dpiencargado"
                                label="DPI/CUI Encargado"
                                readonly
                                @input="$v.dpiencargado.$touch()"
                                @blur="$v.dpiencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row
                          class="pa-2">
                            <v-col
                            cols="12"
                            md="8"
                            >
                              <v-text-field
                                  v-model="direccionencargado"
                                  label="Dirección de domicilio"
                                  readonly
                                  @input="$v.direccionencargado.$touch()"
                                  @blur="$v.direccionencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                              <v-text-field
                                  v-model="telefonodomicilioencargado"
                                  label="Teléfono domicilio Encargado"
                                  readonly
                                  @input="$v.telefonodomicilioencargado.$touch()"
                                  @blur="$v.telefonodomicilioencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row
                          class="pa-2">
                            <v-col
                            cols="12"
                            md="3"
                            >
                              <v-text-field
                                  v-model="movilencargado"
                                  label="Móvil Encargado"
                                  readonly
                                  @input="$v.movilencargado.$touch()"
                                  @blur="$v.movilencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                              <v-text-field
                                  v-model="correoencargado"
                                  label="Correo Encargado"
                                  readonly
                                  @input="$v.correoencargado.$touch()"
                                  @blur="$v.correoencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                              <v-text-field
                                  v-model="profesionencargado"
                                  label="Profesión/Ocupación"
                                  readonly
                                  @input="$v.profesionencargado.$touch()"
                                  @blur="$v.profesionencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                              <v-text-field
                                  v-model="telefonotrabajoencargado"
                                  label="Teléfono de Trabajo Encargado"
                                  readonly
                                  @input="$v.telefonotrabajoencargado.$touch()"
                                  @blur="$v.telefonotrabajoencargado.$touch()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        <div class="text-right">
                          <v-btn 
                          color="secondary"
                          class="mr-2"
                          @click="dialog = false">
                          Cancel
                          </v-btn>

                          <v-btn
                            color="primary"
                            @click="a1 = 2"
                          >
                            Continue
                          </v-btn>
                        </div>

                      </v-stepper-content>

                      <v-stepper-content step="2">
                        <v-card-title> Referencias </v-card-title>

                        <v-row 
                        class="pa-2"
                        >
                          <v-col
                          cols="12"
                          md="6"
                          >
                          <v-text-field
                            class="form-control" 
                            v-model="nombresreferencias"
                            label="Nombres"
                          ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="6"
                          >
                          <v-text-field
                            label="Apellidos"
                            class="form-control" 
                            v-model="apellidosreferencias"
                          ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            >
                            <v-text-field
                              class="form-control" 
                              v-model="telefonoreferencias"
                              label="Teléfono"
                            ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                            <v-text-field
                              label="Correo"
                              class="form-control" 
                              v-model="correoreferencias"
                            ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                            <v-text-field
                              label="Tipo de relación"
                              class="form-control" 
                              v-model="relacionreferencias"
                            ></v-text-field>
                            </v-col>
                        </v-row>                        
                      <v-divider></v-divider>
                        <v-card-title> Archivos adjuntos </v-card-title>
                          <div>
                            <v-file-input small-chips multiple label="archivos"></v-file-input>
                          </div>
                      <v-divider></v-divider>
                        <v-card-title> ¿Cómo se enteró? </v-card-title>
                        <v-layout>
                          <v-row>
                            <v-col
                              cols="12"
                              md="4"
                            >
                              <v-text-field
                                  v-model="medio"
                                  label="Medio"
                                  readonly
                                  @change="$v.medio.$touch()"
                                  @blur="$v.medio.$touch()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-layout>


                        <div class="text-right">
                          <v-btn 
                          color="secondary"
                          class="mr-2"
                          @click="dialog = false">
                          Cancel
                          </v-btn>

                          <v-btn
                            color="primary"
                            @click="a1 = 3"
                          >
                            Continue
                          </v-btn>
                        </div>
                      </v-stepper-content>

                      <v-stepper-content step="3">
                        <v-card-title> Proceso finalizado con éxito </v-card-title>
                        <p class="text-justify"> Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veritatis esse soluta consectetur eaque reiciendis ipsa accusamus suscipit recusandae laudantium illo. Dolorem iure accusamus corporis quod autem reprehenderit exercitationem ipsum laboriosam?</p>
                        
                        <div class="text-right">
                          <v-btn 
                          color="secondary"
                          class="mr-2"
                          @click="dialog = false">
                          Cancel
                          </v-btn>

                          <v-btn
                            color="primary"
                            @click="dialog = false">
                            Continue
                          </v-btn>
                        </div>

                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
              </v-dialog>
          </div>

      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import RadialProgressBar from 'vue-radial-progress' 
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'
  import dialog from "@/components/dialog.vue"

  export default {
    mixins: [validationMixin],

    validations: {
      name: { required, maxLength: maxLength(10) },
      apellido: { required, maxLength: maxLength(10) },
      email: { required, email },
      sede: { required },
      genero: { required },
      dpi: { required, maxLength: maxLength(15) },
      ciclo:{ required },
      jornada:{ required },
      nivel:{ required },
      grado:{ required },
      namemadre:{ required },
      namepadre:{ required },
      nombresencargado:{ required },
      apellidoencargado:{ required },
      apellidomadre:{ required },
      apellidopadre:{ required },
      dpiencargado:{ required },
      dpimadre:{ required },
      dpipadre:{ required },
      direccionencargado:{ required },
      direccionmadre:{ required },
      direccionpadre:{ required },
      telefonodomicilioencargado:{ required },
      telefonodomiciliomadre:{ required },
      telefonodomiciliopadre:{ required },
      movilencargado:{ required },
      movilmadre:{ required },
      movilpadre:{ required },
      correoencargado:{ required },
      correomadre:{ required },
      correopadre:{ required },
      profesionencargado:{ required },
      profesionmadre:{ required },
      profesionpadre:{ required },
      telefonotrabajoencargado:{ required },
      telefonotrabajomadre:{ required },
      telefonotrabajopadre:{ required },
      parentescoencargado:{ required },
      checkbox: {
        checked (val) {
          return val
        },
      },
    },

    data: () => ({
      tab: "tab1",
      name: '',
      apellido: '',
      email: '',
      sede: null,
      genero: null,
      ciclo: null,
      jornada: null,
      nivel: null,
      grado: null,
      dpi: null,
      items: [
        'Sede 1',
        'Sede 2',
        'Sede 3',
        'Sede 4',
      ],
      generos:[
        'Masculino',
        'Femenino',
      ],
      ciclos:[
        '2019',
        '2020',
      ],
      jornadas:[
        'matutina',
        'vespertina',
      ],
      niveles:[
        'primaria',
        'basicos',
      ],
      grados:[
        'primero',
        'segundo',
      ],
      completedSteps: 1,
      totalSteps: 2,
      checkbox: false,
      previewImage: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      namemadre: '',
      namepadre: '',
      nombresencargado: '',
      apellidoencargado: '',
      apellidomadre: '',
      apellidopadre: '',
      dpiencargado: '',
      dpimadre: '',
      dpipadre: '',
      direccionencargado: '',
      direccionmadre: '',
      direccionpadre: '',
      telefonodomicilioencargad: '',
      telefonodomiciliomadre: '',
      telefonodomiciliopadre: '',
      movilencargado: '',
      movilmadre: '',
      movilpadre: '',
      correoencargado: '',
      correomadre: '',
      correopadre: '',
      profesionencargado: '',
      profesionmadre: '',
      profesionpadre: '',
      telefonotrabajoencargado: '',
      telefonotrabajomadre: '',
      telefonotrabajopadre: '',
      parentescoencargado: '',
      telefonodomicilioencargado: '',
      e1: 1,
      inputs: [
        {
          nombresreferencias: '',
          apellidosreferencias: '',
          telefonoreferencias: '',
          correoreferencias: '',
          relacionreferencias: ''
        }
      ],
      medios:[
        'FB',
        'Whatsapp',
        'Twitter',
      ],
      dialog: false,
      a1: 1
    }),
    components: {
      RadialProgressBar,
      dialog,
    },

    computed: {
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('You must agree to continue!')
        return errors
      },
      sedeErrors () {
        const errors = []
        if (!this.$v.sede.$dirty) return errors
        !this.$v.sede.required && errors.push('La sede es requerida')
        return errors
      },
      generoErrors () {
        const errors = []
        if (!this.$v.genero.$dirty) return errors
        !this.$v.genero.required && errors.push('El género es requerido')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Nombre debe ser no mayor a 10 caracteres')
        !this.$v.name.required && errors.push('Nombre es obligatorio')
        return errors
      },
      apellidoErrors () {
        const errors = []
        if (!this.$v.apellido.$dirty) return errors
        !this.$v.apellido.maxLength && errors.push('Apellido debe ser no mayor a 10 caracteres')
        !this.$v.apellido.required && errors.push('Apellido es obligatorio')
        return errors
      },
      dpiErrors () {
        const errors = []
        if (!this.$v.dpi.$dirty) return errors
        !this.$v.dpi.maxLength && errors.push('DPI/CUI debe ser no mayor a 15 caracteres')
        !this.$v.dpi.required && errors.push('DPI/CUI es requerido')
        return errors
      },
      cicloErrors () {
        const errors = []
        if (!this.$v.ciclo.$dirty) return errors
        !this.$v.ciclo.required && errors.push('El ciclo es requerido')
        return errors
      },
      jornadaErrors () {
        const errors = []
        if (!this.$v.jornada.$dirty) return errors
        !this.$v.jornada.required && errors.push('La Jornada es requerido')
        return errors
      },
      nivelErrors () {
        const errors = []
        if (!this.$v.nivel.$dirty) return errors
        !this.$v.nivel.required && errors.push('El nivel es requerido')
        return errors
      },
      gradoErrors () {
        const errors = []
        if (!this.$v.grado.$dirty) return errors
        !this.$v.grado.required && errors.push('El grado es requerido')
        return errors
      },
      namepadreErrors () {
        const errors = []
        if (!this.$v.namepadre.$dirty) return errors
        !this.$v.namepadre.required && errors.push('El nombre es requerido')
        return errors
      },
      apellidopadreErrors () {
        const errors = []
        if (!this.$v.apellidopadre.$dirty) return errors
        !this.$v.apellidopadre.required && errors.push('El apellido es requerido')
        return errors
      },
      dpipadreErrors () {
        const errors = []
        if (!this.$v.dpipadre.$dirty) return errors
        !this.$v.dpipadre.required && errors.push('El DPI/CUI es requerido')
        return errors
      },
      direccionpadreErrors () {
        const errors = []
        if (!this.$v.direccionpadre.$dirty) return errors
        !this.$v.direccionpadre.required && errors.push('La direccion es requerida')
        return errors
      },
      telefonodomiciliopadreErrors () {
        const errors = []
        if (!this.$v.telefonodomiciliopadre.$dirty) return errors
        !this.$v.telefonodomiciliopadre.required && errors.push('El telefono domiciliar es requerido')
        return errors
      },
      movilpadreErrors () {
        const errors = []
        if (!this.$v.movilpadre.$dirty) return errors
        !this.$v.movilpadre.required && errors.push('El movil es requerido')
        return errors
      },
      correopadreErrors () {
        const errors = []
        if (!this.$v.correopadre.$dirty) return errors
        !this.$v.correopadre.required && errors.push('El correo es requerido')
        return errors
      },
      profesionpadreErrors () {
        const errors = []
        if (!this.$v.profesionpadre.$dirty) return errors
        !this.$v.profesionpadre.required && errors.push('La profesión es requerida')
        return errors
      },
      telefonotrabajopadreErrors () {
        const errors = []
        if (!this.$v.telefonotrabajopadre.$dirty) return errors
        !this.$v.telefonotrabajopadre.required && errors.push('El teléfono de trabajo es requerido')
        return errors
      },
      parentescoencargadoErrors () {
        const errors = []
        if (!this.$v.parentescoencargado.$dirty) return errors
        !this.$v.parentescoencargado.required && errors.push('El parentesco es requerido')
        return errors
      },
      nombresencargadoErrors () {
        const errors = []
        if (!this.$v.nombresencargado.$dirty) return errors
        !this.$v.nombresencargado.required && errors.push('El nombre es requerido')
        return errors
      },
      apellidoencargadoErrors () {
        const errors = []
        if (!this.$v.apellidoencargado.$dirty) return errors
        !this.$v.apellidoencargado.required && errors.push('El apellido es requerido')
        return errors
      },
      dpiencargadoErrors () {
        const errors = []
        if (!this.$v.dpiencargado.$dirty) return errors
        !this.$v.dpiencargado.required && errors.push('El DPI/CUI es requerido')
        return errors
      },
      direccionencargadoErrors () {
        const errors = []
        if (!this.$v.direccionencargado.$dirty) return errors
        !this.$v.direccionencargado.required && errors.push('La direccion es requerida')
        return errors
      },
      telefonodomicilioencargadoErrors () {
        const errors = []
        if (!this.$v.telefonodomicilioencargado.$dirty) return errors
        !this.$v.telefonodomicilioencargado.required && errors.push('El telefono domiciliar es requerido')
        return errors
      },
      movilencargadoErrors () {
        const errors = []
        if (!this.$v.movilencargado.$dirty) return errors
        !this.$v.movilencargado.required && errors.push('El movil es requerido')
        return errors
      },
      correoencargadoErrors () {
        const errors = []
        if (!this.$v.correoencargado.$dirty) return errors
        !this.$v.correoencargado.required && errors.push('El correo es requerido')
        return errors
      },
      profesionencargadoErrors () {
        const errors = []
        if (!this.$v.profesionencargado.$dirty) return errors
        !this.$v.profesionencargado.required && errors.push('La profesión es requerida')
        return errors
      },
      telefonotrabajoencargadoErrors () {
        const errors = []
        if (!this.$v.telefonotrabajoencargado.$dirty) return errors
        !this.$v.telefonotrabajoencargado.required && errors.push('El teléfono de trabajo es requerido')
        return errors
      },
      namemadreErrors () {
        const errors = []
        if (!this.$v.namemadre.$dirty) return errors
        !this.$v.namemadre.required && errors.push('El nombre es requerido')
        return errors
      },
      apellidomadreErrors () {
        const errors = []
        if (!this.$v.apellidomadre.$dirty) return errors
        !this.$v.apellidomadre.required && errors.push('El apellido es requerido')
        return errors
      },
      dpimadreErrors () {
        const errors = []
        if (!this.$v.dpimadre.$dirty) return errors
        !this.$v.dpimadre.required && errors.push('El DPI/CUI es requerido')
        return errors
      },
      direccionmadreErrors () {
        const errors = []
        if (!this.$v.direccionmadre.$dirty) return errors
        !this.$v.direccionmadre.required && errors.push('La direccion es requerida')
        return errors
      },
      telefonodomiciliomadreErrors () {
        const errors = []
        if (!this.$v.telefonodomiciliomadre.$dirty) return errors
        !this.$v.telefonodomiciliomadre.required && errors.push('El telefono domiciliar es requerido')
        return errors
      },
      movilmadreErrors () {
        const errors = []
        if (!this.$v.movilmadre.$dirty) return errors
        !this.$v.movilmadre.required && errors.push('El movil es requerido')
        return errors
      },
      correomadreErrors () {
        const errors = []
        if (!this.$v.correomadre.$dirty) return errors
        !this.$v.correomadre.required && errors.push('El correo es requerido')
        return errors
      },
      profesionmadreErrors () {
        const errors = []
        if (!this.$v.profesionmadre.$dirty) return errors
        !this.$v.profesionmadre.required && errors.push('La profesión es requerida')
        return errors
      },
      telefonotrabajomadreErrors () {
        const errors = []
        if (!this.$v.telefonotrabajomadre.$dirty) return errors
        !this.$v.telefonotrabajomadre.required && errors.push('El teléfono de trabajo es requerido')
        return errors
      },
    },

    methods: {
      uploadImage(e){
        const image = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewImage = e.target.result;
            console.log(this.previewImage);
        };
      },
      onlyNumbers ($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (keyCode < 48 || keyCode > 57) { 
          $event.preventDefault();
        }
      },
      
      submit () {
        this.$v.$touch();

      },
      validate () {
        this.e1 = 2;
      },
      add(index) {
        this.inputs.push(
          { 
            nombresreferencias: '' ,
            apellidosreferencias: '',
            telefonoreferencias: '',
            correoreferencias: '',
            relacionreferencias: '',
            });
      },
      remove(index) {
        this.inputs.splice(index, 1);
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.apellido = ''
        this.email = ''
        this.sede = null
        this.genero = null
        this.dpi = null
        this.ciclo = null
        this.jornada = null
        this.nivel = null
        this.grado = null
        this.namemadre = ''
        this.namepadre = ''
        this.nombresencargado = ''
        this.apellidoencargado = ''
        this.apellidomadre = ''
        this.apellidopadre = ''
        this.dpiencargado = ''
        this.dpimadre = ''
        this.dpipadre = ''
        this.direccionencargado = ''
        this.direccionmadre = ''
        this.direccionpadre = ''
        this.telefonodomicilioencargado = ''
        this.telefonodomiciliomadre = ''
        this.telefonodomiciliopadre = ''
        this.movilencargado = ''
        this.movilmadre = ''
        this.movilpadre = ''
        this.correoencargado = ''
        this.correomadre = ''
        this.correopadre = ''
        this.profesionencargado = ''
        this.profesionmadre = ''
        this.profesionpadre = ''
        this.telefonotrabajoencargado = ''
        this.telefonotrabajomadre = ''
        this.telefonotrabajopadre = ''
      },
    },
  }
</script>
<style>
  .theme--light.v-divider{
     border-color: #f7a424 !important;
  }
  .uploading-image{
     display:flex;
  }
  .custom-file-upload input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    cursor: pointer;
  }
  .date_picker{
    text-align: center;
    border-bottom: 1px solid;
    border-color: rgba(0,0,0,.42);
    padding-bottom: 5px;
  }
  .v-stepper, .v-stepper__header{
    box-shadow: unset !important;
  }
  .imagen_dialog{
    margin: auto;
    border: 1px solid;
    border-radius: 70px;
    border-color: #14b3d3;
  }
</style>